.ag-row-footer {
  font-weight: bold;
  background-color: #eee !important;
  border-bottom: 2px solid #ddd !important;
}

.ag-value-change-delta-up, .ag-value-change-delta-down {
  padding-right: 18px;
}

.data-grid-cell-current {
  background-color: #DAEEF9;
}